import useSWR from 'swr';

function usePublicAssets({ filters }: AssetProps) {
  const url = `${process.env.REACT_APP_ASSET_API_URL}public/${process.env.REACT_APP_AFFILIATE_NAME}/assets`;

  function createFilterQuery(): string {
    const searchParams = new URLSearchParams();

    if (filters.length <= 0) {
      return '';
    }

    filters.forEach((filter) => {
      searchParams.append(`filter[${filter.key}]`, filter.values.join(','));
    });

    return `?${searchParams.toString()}`;
  }

  const {
    data,
    error,
  } = useSWR<AssetsResponse>(url + createFilterQuery(), { refreshInterval: 60000 });

  return {
    assets: data ? data.data : [],
    paginationMeta: data ? data.meta : {
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as PaginationMeta,
    isLoading: !error && !data,
    isError: error,
  };
}

export type Property = {
  id: string
  key: string
  value: string
  type: string
};

type AssetsResponse = {
  data: Asset[]
  meta: PaginationMeta
};

export type PaginationMeta = {
  current_page: number,
  from: number,
  last_page: number,
  per_page: number,
  to: number,
  total: number
};

export type Asset = {
  id: string
  name: string
  properties: Property[]
  created_at: string
  updated_at: string
};

export type Filter = {
  key: string
  values: string[]
};

type AssetProps = {
  filters: Filter[]
};

export default usePublicAssets;
