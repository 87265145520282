import React from 'react';
import Checkbox, { Option } from './Checkbox';

function MultiCheckbox({ options, onChangeHandler }: CheckboxProps) {
  return (
    <ul>
      {options.map((option, _index) => (
        <li key={`cb-${option.index}-${option.value}`}>
          <Checkbox
            option={option}
            onChange={(selectedOption: Option) => {
              onChangeHandler(options.map(
                (listOption) => (listOption.index === selectedOption.index
                  ? selectedOption : listOption),
              ));
            }}
          />
        </li>
      ))}
    </ul>
  );
}

type CheckboxProps = {
  options: Option[]
  onChangeHandler: (options: Option[]) => void
};

export default MultiCheckbox;
