import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: hsl(0, 1%, 16%);
  overflow-x: hidden;
}
`;

export default GlobalStyles;
