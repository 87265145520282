import { createGlobalStyle } from 'styled-components';

const SittardStyle = createGlobalStyle`
body,
html,
.map {
    height: 100vh;
    min-width: 100%;
    color: black !important;
}

.btn {
  background-color: rgba(63, 106, 0) !important;
}

.btn:hover {
    background-color: rgba(63, 106, 0, 0.6) !important;
  }

.sensor-type-icon  {
  color: rgba(63, 106, 0) !important;
}

.header {
    background-color: white !important;
    height: 100px;
    color: black !important;
}

.logo {
    width: 300px;
}

.header-links {
    justify-self: end;
}

.logo-container{
    color: #c9d200;
}

.leaflet-container {
    position: relative;
    height: 100%;
}

.marker-cluster-small div {
    background-color: rgba(63, 106, 0) !important;
}

.marker-cluster-small {
    background-color: rgba(63, 106, 0, 0.6) !important;
}

.marker-cluster-small div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-medium div {
    background-color: rgba(63, 106, 0) !important;
}

.marker-cluster-medium {
    background-color: rgba(63, 106, 0, 0.6) !important;
}

.marker-cluster-medium div span {
    color: white;
    font-weight: bold;
}

.marker-cluster-large div {
    background-color: rgba(63, 106, 0) !important;
}

.marker-cluster-large {
    background-color: rgba(63, 106, 0, 0.6) !important;
}

.marker-cluster-large div span {
    color: white;
    font-weight: bold;
}

.hamburger-icon {
    color: #0062A7 !important;
}
`;
export default SittardStyle;
