import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

function Loading() {
  return (
    <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
  );
}

export default Loading;
