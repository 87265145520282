import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { SWRConfig } from 'swr';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import App from './App';
import * as nl from './locales/nl/translation.json';
import * as en from './locales/en/translation.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
const fetcher = async (url, init) => {
  const res = await fetch(url, init);

  // Wrap all responses that are not ok in an error
  if (!res.ok) {
    const error = new Error('An error occurred');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

i18n
  .use(initReactI18next)
  .init({
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    lng: 'nl', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    resources: {
      nl: {
        translation: nl,
      },
      en: {
        translation: en,
      },
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: (resource, init) => fetcher(resource, init),
      }}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SWRConfig>
  </React.StrictMode>,
);
