import React from 'react';

function AboutBrabant() {
  return (
    <div className="flex justify-center">
      <div className="text-black max-w-2xl space-y-3 mb-10">
        <p className="pt-2 text-2xl font-bold pb-1">
          Over het sensorregister van de provincie Noord-Brabant
        </p>
        <p>
          Via dit sensorregister vindt u informatie over de sensoren die de
          provincie Noord-Brabant als organisatie inzet in de publieke ruimte.
          Deze sensoren staan op een interactieve kaart, waarop u de locaties
          van de sensoren kunt bekijken.
        </p>
        <p className="font-bold">
          Type sensoren
        </p>
        <p>
          De provincie maakt gebruik van verschillende type sensoren met verschillende
          doeleinden. Bijvoorbeeld:
        </p>
        <li>
          Infraroodsensoren en wildcamera‘s langs provinciale wegen detecteren overstekend wild
          om automobilisten op tijd te waarschuwen
        </li>
        <li>
          Temperatuursensoren in het wegdenk van provinciale wegen geven inzicht wanneer
          onze strooiwagens moeten uitrukken
        </li>
        <li>
          Peilbuizen meten grondwaterstanden om de grondwaterhoogte te monitoren en
          bodemverdroging tegen te gaan.
        </li>
        <li>
          Luchtmeetstations bewaken de luchtkwaliteit bij industriegebieden
          en grijpen in bij overschrijding van gezondheidsnormen.
        </li>
        <p>
          Alle sensoren zijn ingedeeld in een aantal standaardtypes, zoals camera/optisch
          voor wildsensoren, temperatuur voor temperatuursensoren en klimaat voor peilbuizen
          en luchtmeetstations. U kunt deze typen filteren in het pop-upmenu
          links aan de linkerkant van het scherm.
        </p>
        <p className="font-bold">
          Herkenbare pictogrammen
        </p>
        <p>
          Ieder type sensor is voorzien van een eigen pictogram. Door op dit
          pictogram te klikken, vindt u meer informatie over de betreffende sensor.
        </p>
        <p className="font-bold">
          Aanvullende informatie en contactgegevens
        </p>
        <p>
          Informatie over de eigenaar van de sensor en contactgegevens zijn ook beschikbaar.
        </p>
        <p>
          Daarnaast biedt dit sensorregister aanvullende informatie over de sensor, zoals over de
          verwerking van persoonsgegevens, de wettelijke grondslag
          voor het gebruik, technische specificaties en informatie over wat er
          gebeurt met de sensordata.
        </p>
        <p>
          Alle relevante links naar informatie over bijvoorbeeld privacy, wet- en regelgeving,
          technische brochures en de (open) data zijn hier beschikbaar.
        </p>
      </div>
    </div>
  );
}

export default AboutBrabant;
