import { Link, Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoadAffiliate from '../partners/LoadAffiliate';

function Layout() {
  const affiliateLogo = LoadAffiliate()?.logo;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);
  const [showMenu, setShowMenu] = useState(false);
  const handleHamburgerMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className="map overflow-y-auto">
      <div className="header flex bg-wc-blue top-0 z-[420] h-24 py-2">
        <div className="flex flex-grow flex-wrap items-center">
          <div className="logo-container flex-grow justify-self-start text-wc-gray-500 text-sm text-center mx-4">
            <Link to="/" className="header text-white">
              {affiliateLogo}
            </Link>
          </div>

          {isMobile ? (
            <div className="hamburger-menu relative inline-block text-left">
              <button type="button" className="inline-flex w-full self-center justify-center flex-end px-3 py-2" id="menu-button" aria-expanded="true" aria-haspopup="true">
                <FontAwesomeIcon className="hamburger-icon text-white" icon={icon({ name: 'bars', family: 'classic', style: 'solid' })} size="2x" onClick={() => handleHamburgerMenu()} />
              </button>
              {showMenu && (
                <div className="absolute right-0 z-[500] w-56 py-1 origin-top-right rounded-md bg-white text-gray-700 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu">
                  <ul>
                    <li><Link to="/" className="mx-4">Kaart</Link></li>
                    <li><Link to="/about" className="mx-4">Over het register</Link></li>
                    <li><Link to="/disclaimer" className="mx-4">Disclaimer</Link></li>
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="header-links flex-end h-full flex flex-row items-center text-lg">
              <div>
                <Link to="/" className="header mx-4 text-white">Kaart</Link>
              </div>
              <div>
                <Link to="/about" className="header mx-4 text-white">Over het register</Link>
              </div>
              <div>
                <Link to="/disclaimer" className="header mx-4 text-white">Disclaimer</Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Layout;
