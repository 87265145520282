import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

function SensorTypeIcon({ sensorType, size }: SensorTypeIconProps) {
  function getIcon(st: string) {
    switch (st) {
      case 'camera':
        return <FontAwesomeIcon icon={icon({ name: 'camera-cctv', family: 'classic', style: 'solid' })} size={size} />;
      case 'sound':
        return <FontAwesomeIcon icon={icon({ name: 'volume', family: 'classic', style: 'solid' })} size={size} />;
      case 'chemical':
        return <FontAwesomeIcon icon={icon({ name: 'flask', family: 'classic', style: 'solid' })} size={size} />;
      case 'climate':
        return <FontAwesomeIcon icon={icon({ name: 'sun-cloud', family: 'classic', style: 'solid' })} size={size} />;
      case 'electrical':
        return <FontAwesomeIcon icon={icon({ name: 'bolt', family: 'classic', style: 'solid' })} size={size} />;
      case 'pressure':
        return <FontAwesomeIcon icon={icon({ name: 'dumbbell', family: 'classic', style: 'solid' })} size={size} />;
      case 'liquid':
        return <FontAwesomeIcon icon={icon({ name: 'droplet', family: 'classic', style: 'solid' })} size={size} />;
      case 'temperature':
        return <FontAwesomeIcon icon={icon({ name: 'temperature-full', family: 'classic', style: 'solid' })} size={size} />;
      case 'presence':
        return <FontAwesomeIcon icon={icon({ name: 'people-arrows', family: 'classic', style: 'solid' })} size={size} />;
      case 'density':
        return <FontAwesomeIcon icon={icon({ name: 'cubes-stacked', family: 'classic', style: 'solid' })} size={size} />;
      case 'position':
        return <FontAwesomeIcon icon={icon({ name: 'compass', family: 'classic', style: 'solid' })} size={size} />;
      default:
        return '';
    }
  }

  return (
    <div className="w-5 content-center text-center">
      {getIcon(sensorType)}
    </div>
  );
}

type SensorTypeIconProps = {
  sensorType: string
  size: SizeProp
};

export default SensorTypeIcon;
