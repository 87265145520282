import { Popup } from 'react-leaflet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, Property } from '../hooks/usePublicAssets';

function SensorPopup({ asset }: SensorPopupProps) {
  const { t } = useTranslation();

  function getProperty(key: string): Property | undefined {
    return asset.properties.find((property) => property.key === key);
  }

  const contactEmail = getProperty('contact_email')?.value;
  const legislationUrl = getProperty('legislation_url')?.value;

  return (
    <Popup maxHeight={500} minWidth={300} className="z-[430]">
      <div className="space-y-1">
        <h2 className="text-base font-bold pb-1 capitalize-first pr-2">
          {asset.name}
        </h2>
        <div className="pb-1">{t(getProperty('goal')?.value ?? '')}</div>
        <ul className="pb-2">
          <li className="font-bold m-0">
            {t('sensor characteristics')}
            :
          </li>
          <li>
            <span className="inline-block capitalize-first m-0">
              {t('sensor type')}
              :
              {' '}
              {t(
                (getProperty('sensor_type')?.value ?? 'geen doel').replaceAll('_', ' '),
              )}
            </span>
          </li>
          <li>
            {(getProperty('processes_personal_data')?.value === '1') ? (
              <span className="inline-block capitalize-first m-0">
                {t('sensor processes personal data')}
              </span>
            ) : (
              <span className="inline-block capitalize-first m-0">
                {t('sensor does not process personal data')}
              </span>
            )}
          </li>
          <li className="capitalize-first">
            {(getProperty('is_mobile')?.value === '1') ? (
              <span className="inline-block capitalize-first m-0">
                {t('sensor is moveable')}
              </span>
            ) : (
              <span className="inline-block capitalize-first m-0">
                {t('sensor is fixed')}
              </span>
            )}
          </li>
        </ul>
        {(legislationUrl) && (
          <div><a target="_blank" href={legislationUrl} rel="noreferrer">{t('legislation')}</a></div>
        )}
        {(contactEmail) ? (
          <div className="flex flex-row space-x-1">
            <div className="capitalize">{t('contact')}</div>
            <a target="_blank" href={`mailto:${contactEmail}`} rel="noreferrer">
              {
                getProperty('operating_organization')?.value ?? t('organization')
              }
            </a>
          </div>
        ) : (
          <div className="flex flex-row space-x-1">
            {
              getProperty('operating_organization')?.value ?? t('organization')
            }
          </div>
        )}
      </div>
    </Popup>
  );
}

type SensorPopupProps = {
  asset: Asset
};

export default SensorPopup;
