import React from 'react';

function Disclaimer() {
  return (
    <div className="flex justify-center">
      <div className="text-black max-w-2xl space-y-3">
        <h1 className="text-3xl pt-3">
          Disclaimer
          {' '}
          <div className="inline capitalize">{process.env.REACT_APP_AFFILIATE_NAME}</div>
        </h1>
        <p>
          De informatie uit dit register mag alleen gebruikt worden voor het doel waarvoor het is
          opgericht. U informeren over de sensoren die wij als organisatie in de
          publieke ruimte gebruiken.
        </p>
        <p>
          Bij het registreren van onze sensoren in dit register volgen wij de geldende wet- en
          regelgeving en de kaders en richtlijnen zoals die binnen
          {' '}
          {process.env.REACT_APP_AFFILIATE_NAME}
          {' '}
          gelden.
          Hoewel wij onze registratie periodiek bijwerken om dit sensorregister een zo compleet en
          actueel te houden, kan het voorkomen dat er sensoren en/of gegevens ontbreken of onjuist
          zijn. Wij vragen uw begrip hiervoor.
        </p>
      </div>
    </div>
  );
}

export default Disclaimer;
