import './index.css';

import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from './components/Layout';
import LeafletMap from './components/LeafletMap';
import LoadAffiliate from './partners/LoadAffiliate';
import GlobalStyles from './partners/GlobalStyles';

function App() {
  const affiliate = LoadAffiliate();

  return (
    <ThemeProvider theme={GlobalStyles}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LeafletMap />} />
          <Route path="about" element={affiliate.about} />
          <Route path="disclaimer" element={affiliate.disclaimer} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<LeafletMap />} />
        </Route>
      </Routes>
      {affiliate.style}
    </ThemeProvider>
  );
}

export default App;
