import useSWR from 'swr';

function useRegisteredSensorSchema() {
  const { data, error } = useSWR<SchemeResponse>(`${process.env.REACT_APP_ASSET_API_URL}public/scheme`);

  return {
    schema: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
}

export type Schema = {
  name: string
  propertyTypes: PropertyType[]
};

type SchemeResponse = {
  data: Schema
};

export type PropertyType = {
  relation: string
  name: string
  validation: string
  description: string
};

export default useRegisteredSensorSchema;
