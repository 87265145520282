import React from 'react';

function BrabantDisclaimer() {
  return (
    <div className="flex justify-center">
      <div className="text-black max-w-2xl space-y-3">
        <p className="pt-2 text-2xl font-bold pb-1">
          Disclaimer Provincie Noord-Brabant
        </p>
        <p>
          De informatie uit dit register mag alleen gebruikt worden voor
          het doel waarvoor het is opgericht: u informeren over de sensoren
          die wij als provinciale organisatie in de publieke ruimte gebruiken.
        </p>
        <p>
          Bij het registreren van onze sensoren in dit register volgen wij de geldende
          wet- en regelgeving en de kaders en richtlijnen zoals die binnen de provincie
          Noord-Brabant gelden.
        </p>
        <p>
          Hoewel wij onze registratie periodiek bijwerken om dit sensorregister zo compleet en
          actueel mogelijk te houden, kan het voorkomen dat er sensoren
          en/of gegevens ontbreken of onjuist zijn. Wij vragen uw begrip hiervoor.
        </p>
      </div>
    </div>
  );
}

export default BrabantDisclaimer;
